import { WithProperty } from '@/utilities.types';
import _ from 'lodash';
import { useState } from 'react';

export const useTableSelect = <T extends WithProperty<{ id: string }>>(items: T[] = []) => {
  const [allSelected, setAllSelected] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onSelectAll = () => {
    setSelectedIds(allSelected ? [] : _.map(items, 'id'));
    setAllSelected(!allSelected);
  };

  const onRowSelect = (itemId: string) => {
    const isRowSelected = _.indexOf(selectedIds, itemId) > -1;
    setSelectedIds(isRowSelected ? [..._.pull(selectedIds, itemId)] : [...selectedIds, itemId]);
  };

  const clearSelectedRows = () => {
    setSelectedIds([]);
    allSelected && setAllSelected(false);
  };

  return { onRowSelect, onSelectAll, clearSelectedRows, allSelected, selectedIds };
};
