export const DEFAULT_NOTIFICATION_CONFIGURATION = {
  toEmailRecipients: [],
  bccEmailRecipients: [],
  ccEmailRecipients: [],
  capsuleProperties: [],
};

export enum NotificationEditingContext {
  Properties = 'Properties',
  NotificationManagement = 'NotificationManagement',
}
