import { useState } from 'react';

export interface ReloadTableTrigger {
  reload: true;
}

export const useReloadTable = () => {
  const [reloadTableTrigger, setReloadTableTrigger] = useState<ReloadTableTrigger>({ reload: true });

  const reloadTable = () => setReloadTableTrigger({ reload: true });

  return { reloadTable, reloadTableTrigger };
};
