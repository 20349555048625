import { ButtonWithPopover } from '@/core/ButtonWithPopover.molecule';
import { Icon } from '@/core/Icon.atom';
import _ from 'lodash';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MoreActions } from '@/notifications/notification.types';
import { WithProperty } from '@/utilities.types';

export interface MoreActionsButtonProps<T> {
  item: T;
  moreActions: MoreActions<T>[];
  loadItems: () => void;
}

export const MoreActionsButton = <T extends WithProperty<{ id: string }>>({
  item,
  moreActions,
  loadItems,
}: MoreActionsButtonProps<T>) => {
  const { t } = useTranslation();

  const id = `${item.id}MoreActionsMenu`;

  return (
    <ButtonWithPopover
      popoverConfig={{ id, placement: 'bottom-end' }}
      label={
        <Icon
          icon="fc-more"
          extraClassNames="sq-navbar-default flexNoGrowNoShrink pr12"
          testId={`${id}Label`}
          id="options"
        />
      }>
      {_.map(moreActions, ({ href, translationKey, testId, iconClass, action, display }) => {
        if (!display) {
          return null;
        }

        const key = translationKey(item);

        return (
          <Dropdown.Item
            href={href}
            key={`dropdown_${key}`}
            onClick={() => action(item, loadItems)}
            target="_blank"
            className="sq-force-text-gray"
            data-testid={testId}>
            <Icon icon={`${iconClass(item)} fa-fw`} type="inherit" />
            <span className="forceFont pl10" data-testid={`moreActions_${key}`}>
              {t(key)}
            </span>
          </Dropdown.Item>
        );
      })}
    </ButtonWithPopover>
  );
};
