import { CELL_TYPES, TableColumn } from '@/core/Table.atom';
import _ from 'lodash';
import React, { useState } from 'react';
import { NotifiableReportOutputV1, sqContentApi, sqNotificationConfigurationsApi } from '@/sdk';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import { FakeLink } from '@/core/FakeLink';
import { MoreActions } from '@/notifications/notification.types';
import { MoreActionsButton } from '@/notifications/management/MoreActionsButton';
import { EmptyReportTable } from '@/notifications/management/EmptyReportTable';
import { FetchItemsParams, PaginationTable } from '@/notifications/management/PaginationTable';
import { BulkTableActionOptions, BulkTableActionsConfig } from '@/notifications/management/BulkTableActions';
import { useReloadTable } from '@/notifications/management/hooks/useReloadTable.hook';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { sqWorkbenchStore } from '@/core/core.stores';
import { DEFAULT_SORT, REPORT_TABLE_CANCELLATION_GROUP } from '@/notifications/notification.constants';
import { ConfirmDeleteNotificationModal } from '@/notifications/management/ConfirmDeleteNotificationModal';
import { IdentityOption } from '@/core/SelectIdentity.molecule';
import { UserFilterTableHeader } from '@/core/UserFilterTableHeader';

const extractContextUrl = (text?: string) => {
  const match = text?.match(/.*href="(.*?)".*/);
  return match ? match[1] : undefined;
};
const openContextUrl = (url?: string) => url && window.open(url, '_blank');

export const ReportTable = () => {
  const currentUser = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.currentUser);

  const [userIdentities, setIdentities] = useState<IdentityOption[]>([]);
  const [deleteConfig, setDeleteConfig] = useState<
    BulkTableActionOptions<NotifiableReportOutputV1> | NotifiableReportOutputV1
  >();

  const { reloadTable, reloadTableTrigger } = useReloadTable();

  const moreActions: MoreActions<NotifiableReportOutputV1>[] = [
    {
      href: '#',
      iconClass: _.constant('fa-link'),
      translationKey: _.constant('NOTIFICATIONS_MANAGEMENT.REPORT.OPEN'),
      action: (item) => openContextUrl(extractContextUrl(item.contextualText)),
      display: true,
      testId: 'openOrganizer',
    },
    {
      href: '#',
      iconClass: _.constant('fa-trash'),
      translationKey: _.constant('NOTIFICATIONS_MANAGEMENT.REPORT.DELETE'),
      action: (item) => setDeleteConfig(item),
      display: true,
      testId: 'deleteNotification',
    },
  ];

  const columns: TableColumn<NotifiableReportOutputV1>[] = [
    {
      accessor: 'id',
      cellType: CELL_TYPES.ROW_SELECTION,
      cellStyle: { width: 40, maxWidth: 40 },
      isResizable: false,
    },
    {
      accessor: 'name',
      filterable: true,
      sortable: true,
      searchProperty: 'nameSearch',
      header: 'NOTIFICATIONS_MANAGEMENT.HEADER.NAME',
      cellStyle: { wordWrap: 'break-word' },
      cellRenderFunction: (item, accessor = 'name') => (
        <FakeLink onClick={() => openContextUrl(extractContextUrl(item.contextualText))}>
          {item[accessor] as string}
        </FakeLink>
      ),
    },
    {
      accessor: 'owner.name',
      header: 'NOTIFICATIONS_MANAGEMENT.REPORT.HEADER.OWNER',
      cellStyle: { wordWrap: 'break-word', width: 300 },
      headerRenderFunction: currentUser.isAdmin
        ? (column) => (
            <UserFilterTableHeader
              column={column}
              userIdentities={userIdentities}
              onUserIdentitiesChange={(identities) => {
                setIdentities(identities);
                reloadTable();
              }}
            />
          )
        : undefined,
    },
    {
      accessor: 'enabled',
      header: 'STATUS',
      cellStyle: { width: 100, wordWrap: 'break-word' },
      cellType: CELL_TYPES.ENABLED_DISABLED,
    },
    {
      accessor: 'id',
      cellStyle: { width: 30, maxWidth: 30 },
      cellRenderFunction: (item) => <MoreActionsButton item={item} loadItems={reloadTable} moreActions={moreActions} />,
      isResizable: false,
    },
  ];

  const deleteReportNotification = async (item: NotifiableReportOutputV1) => {
    try {
      await sqNotificationConfigurationsApi.deleteNotificationConfiguration({ id: item.id });
      reloadTable();
      successToast({
        messageKey: 'NOTIFICATIONS_MANAGEMENT.REPORT.DELETE_SUCCESS',
        messageParams: { topicName: item.name },
      });
    } catch (e) {
      errorToast({ httpResponseOrError: e, displayForbidden: true });
    }
  };

  const deleteReportNotifications = async ({
    clearSelectedRows,
    selectedIds,
  }: BulkTableActionOptions<NotifiableReportOutputV1>) => {
    try {
      await Promise.all(
        _.map(selectedIds, (id) => sqNotificationConfigurationsApi.deleteNotificationConfiguration({ id })),
      );
      reloadTable();
      successToast({
        messageKey: 'NOTIFICATIONS_MANAGEMENT.BULK_DELETE_SUCCESS',
        messageParams: { amount: selectedIds.length },
      });
    } catch (e) {
      errorToast({ httpResponseOrError: e, displayForbidden: true });
    } finally {
      clearSelectedRows();
    }
  };

  const bulkActionsConfig: BulkTableActionsConfig<NotifiableReportOutputV1>[] = [
    {
      id: 'deleteSelected',
      label: 'NOTIFICATIONS_MANAGEMENT.REPORT.DELETE_SELECTED',
      onClick: setDeleteConfig,
    },
  ];

  const fetchItems = ({ limit, offset, sortAsc, searchParams = {} }: FetchItemsParams) =>
    sqContentApi.getNotifiableReports(
      {
        limit,
        offset,
        sortAsc,
        ownerIds: currentUser.isAdmin ? _.map(userIdentities, 'id') : [sqWorkbenchStore.currentUser.id],
        ...searchParams,
      },
      { cancellationGroup: REPORT_TABLE_CANCELLATION_GROUP },
    );

  return (
    <>
      <PaginationTable
        testId="reportTable"
        columns={columns}
        itemsAccessor="listOfReports"
        bulkActionsConfig={bulkActionsConfig}
        emptyPlaceHolder={<EmptyReportTable />}
        fetchItems={fetchItems}
        reloadTableTrigger={reloadTableTrigger}
        defaultSort={DEFAULT_SORT}
        extraFilters={{ ownerIds: currentUser.isAdmin ? _.map(userIdentities, 'id') : [] }}
      />

      {deleteConfig && (
        <ConfirmDeleteNotificationModal
          onDelete={() =>
            'selectedIds' in deleteConfig
              ? deleteReportNotifications(deleteConfig)
              : deleteReportNotification(deleteConfig)
          }
          onClose={() => setDeleteConfig(undefined)}
          itemCount={'selectedIds' in deleteConfig ? deleteConfig.selectedIds.length : 1}
        />
      )}
    </>
  );
};
