import React from 'react';
import { useTranslation } from 'react-i18next';

interface EmptyTableProps {
  testId?: string;
  header: string;
  body: string;
  linkText: string;
  linkUrl: string;
}

export const EmptyTable: React.FunctionComponent<EmptyTableProps> = ({
  testId = 'emptyTable',
  header,
  body,
  linkText,
  linkUrl,
}) => {
  const { t } = useTranslation();

  return (
    <div data-testid={testId}>
      <h4 className="text-italic">{t(header)}</h4>
      <p className="max-width-500">{t(body)}</p>
      <a target="_blank" href={linkUrl}>
        {t(linkText)}
      </a>
    </div>
  );
};
