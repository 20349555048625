import React, { useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@/core/Checkbox.atom';
import { DURATION_TIME_UNITS, KEY_CODES } from '@/main/app.constants';
import { ValueWithUnits, ValueWithUnitsItem } from '@/trend/ValueWithUnits.atom';
import { AdvancedSection } from '@/core/AdvancedParameters.atom';
import TimeZoneSelector from '@/core/TimeZoneSelector.molecule';
import { Alert, Collapse } from 'react-bootstrap';
import { FormInput } from '@/core/FormInput.atom';
import { TranslationWithHTML } from '@/core/ContainerWithHTML.atom';
import { ColumnIF, CustomPropertySelector, SuggestedPropertiesMode } from '@/utilities/CustomPropertySelector.atom';
import { Icon } from '@/core/Icon.atom';
import { ConditionMonitorOutputV1, NotificationConfigurationOutputV1 } from '@/sdk';
import { CapsuleGroupingEnum } from '@/sdk/model/NotificationConfigurationOutputV1';
import {
  ConditionMonitorOutputWithNames,
  DEFAULT_PROPERTIES,
  identitiesToRecipients,
} from '@/notifications/notifications.utilities';
import { getValueAndUnits, updateUnits } from '@/datetime/dateTime.utilities';
import { sqTimezones } from '@/utilities/datetime.constants';
import { getConditionMonitorNotificationsScheduleDescription } from '@/services/systemConfiguration.utilities';
import { ToggleSwitch } from '@/core/ToggleSwitch.atom';
import { EmailRecipientsSection } from '@/notifications/EmailRecipientsSection';
import { PropertyColumn } from '@/trendData/trendData.constants';
import { CKEditor } from '@/annotation/CKEditor.organism';
import { CustomPlugin } from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { NotificationEditingContext } from '@/notifications/notifications.constants';
import { FakeLink } from '@/core/FakeLink';
import { SelectedAsset } from '@/core/SelectAssetSearchWidget.molecule';

export interface NotificationsDetailsProps {
  isEditMode: boolean;
  conditionMonitor: ConditionMonitorOutputWithNames;
  notificationConfiguration: NotificationConfigurationOutputV1;
  setConditionMonitor: (conditionMonitor: ConditionMonitorOutputV1) => void;
  setNotificationConfiguration: (notificationConfiguration: NotificationConfigurationOutputV1) => void;
  context: NotificationEditingContext;
  editSchedule: () => void;
  newCronSchedule: string[] | undefined;
  editAsset: () => void;
  setItemFinderAsset: (asset: SelectedAsset | undefined) => void;
  itemFinderAsset?: SelectedAsset;
  itemFinderWarnings?: string;
  setAdvancedSectionExpanded: (isExpanded: boolean) => void;
  advancedSectionExpanded: boolean;
}

export const NotificationsDetails: React.FunctionComponent<NotificationsDetailsProps> = ({
  isEditMode,
  conditionMonitor,
  notificationConfiguration,
  setConditionMonitor,
  setNotificationConfiguration,
  context,
  editSchedule,
  newCronSchedule,
  editAsset,
  setItemFinderAsset,
  itemFinderAsset,
  itemFinderWarnings,
  setAdvancedSectionExpanded,
  advancedSectionExpanded,
}) => {
  const { t } = useTranslation();

  const lookAhead =
    conditionMonitor.queryRangeLookAhead !== '0'
      ? getValueAndUnits(moment.duration(conditionMonitor.queryRangeLookAhead ?? 0, 'seconds'))
      : { value: 0, units: DURATION_TIME_UNITS[3].unit[0], translationKey: 'UNITS.DAYS' };

  const timezone =
    _.find(sqTimezones.timezones, { name: notificationConfiguration.timezone }) ?? sqTimezones.defaultTimezone;
  const schedule = _.isEmpty(conditionMonitor.cronSchedule)
    ? getConditionMonitorNotificationsScheduleDescription()
    : conditionMonitor.cronScheduleDescription;

  const plugins = context === NotificationEditingContext.Properties ? [CustomPlugin.WorksheetLink] : [];
  const toolbar = (plugins as string[]).concat(['link', 'bold', 'italic', 'underline', 'numberedList', 'bulletedList']);
  const isItemFinderEnabled = !_.isNil(itemFinderAsset);

  /**
   * Handler for callback notifications that the document has been changed by the user.
   *
   * @param emailBody - the updated custom email body
   * @returns Promise that resolves when the document has been saved
   */
  const documentChanged = useRef(_.noop);
  useEffect(() => {
    documentChanged.current = (updatedDocument: string): Promise<any> => {
      setNotificationConfiguration({ ...notificationConfiguration, contextualText: updatedDocument });
      return Promise.resolve();
    };
  }, []);

  const setLookAheadAndConditionMonitor = (duration: ValueWithUnitsItem) => {
    const { value } = updateUnits(duration.value, 'seconds', duration.units);
    setConditionMonitor({ ...conditionMonitor, queryRangeLookAhead: value.toString() });
  };

  const getReadableUnits = (givenUnit: string) => {
    const unit = _.find(DURATION_TIME_UNITS, ({ unit }) => _.includes(unit, givenUnit));
    return unit?.translationKey ?? '';
  };

  const isCapsulePropertyChecked = (capsuleProperty: PropertyColumn) =>
    _.includes(notificationConfiguration.capsuleProperties, capsuleProperty.propertyName);

  const isCapsuleGroupingChecked = () => {
    return notificationConfiguration.capsuleGrouping === CapsuleGroupingEnum.ALL;
  };

  const removeFromProperties = (capsuleProperty: PropertyColumn): string[] =>
    _.without(notificationConfiguration.capsuleProperties, capsuleProperty.propertyName) as string[];

  const addToProperties = (capsuleProperty: PropertyColumn): string[] =>
    _.concat(notificationConfiguration.capsuleProperties, capsuleProperty.propertyName) as string[];

  const readOnlyCapsulePropertiesList = (
    <ul className="unstyled-list mb0">
      {_.map(notificationConfiguration.capsuleProperties, (property) => {
        const defaultProperty = _.find(DEFAULT_PROPERTIES, ({ propertyName }) => propertyName === property)?.title;
        const displayText = _.isUndefined(defaultProperty) ? property : (t(defaultProperty) as string);
        return <li key={`readonly-${property}`}>{displayText}</li>;
      })}
    </ul>
  );

  const defaultCheckboxList = () =>
    _.map(DEFAULT_PROPERTIES, (capsuleProperty) => (
      <Checkbox
        classes="textPrimaryMenuItem"
        id={`${capsuleProperty.propertyName}-notifications-checkbox`}
        key={capsuleProperty.key}
        label={capsuleProperty.title!}
        disabled={!isEditMode}
        isChecked={isCapsulePropertyChecked(capsuleProperty)}
        onChange={() => {
          setNotificationConfiguration({
            ...notificationConfiguration,
            capsuleProperties: isCapsulePropertyChecked(capsuleProperty)
              ? removeFromProperties(capsuleProperty)
              : addToProperties(capsuleProperty),
          });
        }}
        skipMemo={true}
      />
    ));

  const getCustomProperties = (capsuleProperties: string[]) => {
    return _.reduce(
      capsuleProperties,
      (accum: string[], current) => {
        const isDefaultProperty = DEFAULT_PROPERTIES.findIndex(
          (defaultProperty) => defaultProperty.propertyName === current,
        );
        if (!~isDefaultProperty) {
          accum.push(current);
        }
        return accum;
      },
      [],
    );
  };

  const customCheckboxList = (capsuleProperties: string[]) =>
    _.map(getCustomProperties(capsuleProperties), (capsuleProperty) => (
      <Checkbox
        classes="textPrimaryMenuItem"
        id={`${capsuleProperty}-notifications-checkbox`}
        key={`${capsuleProperty}-checkbox`}
        label={capsuleProperty}
        isChecked={true}
        disabled={!isEditMode}
        onChange={() => {
          setNotificationConfiguration({
            ...notificationConfiguration,
            capsuleProperties: _.without(notificationConfiguration.capsuleProperties, capsuleProperty),
          });
        }}
        skipMemo={true}
      />
    ));

  const editableCapsulePropertiesList = (
    <>
      <div>
        {defaultCheckboxList()}
        {customCheckboxList(notificationConfiguration.capsuleProperties)}
      </div>
      <div className="width-250">
        {isEditMode && (
          <CustomPropertySelector
            itemIds={conditionMonitor.conditionIds ?? []}
            suggestedPropertiesMode={SuggestedPropertiesMode.Capsules}
            addPropertyColumn={(capsuleProperty: ColumnIF) => {
              setNotificationConfiguration({
                ...notificationConfiguration,
                capsuleProperties: _.uniq(
                  notificationConfiguration.capsuleProperties.concat(capsuleProperty.propertyName),
                ),
              });
            }}
            dropdownPlaceholder="CUSTOM_COLUMN_SELECTOR.CUSTOM_PROPERTY"
            closeMenuOnSelect={false}
          />
        )}
      </div>
    </>
  );

  const topSection = (
    <div className="flexColumnContainer flexSpaceBetween" data-testid="notificationDetailsHeader">
      <div className="text-italic mr50">
        <TranslationWithHTML
          translationKey="NOTIFICATIONS.MODAL.SEEQ_WILL_CHECK"
          translationParams={{
            count: conditionMonitor.conditionIds.length,
            icon: "<i class='fc sq-icon-color fc-capsule-set'></i>",
            name: `<strong>${
              conditionMonitor.conditionNames?.join(', ') ?? conditionMonitor.conditionIds.length
            }</strong>`,
            schedule: newCronSchedule ? `<strong>${t('NOTIFICATIONS.MODAL.CUSTOM_SCHEDULE')}</strong>` : schedule,
          }}
        />
        {isEditMode && (
          <FakeLink onClick={editSchedule} extraClassNames="ml3">
            {t('NOTIFICATIONS.MODAL.CHANGE_SCHEDULE')}
          </FakeLink>
        )}
      </div>
      {isEditMode && (
        <ToggleSwitch
          testId="notificationEnabled"
          isOn={conditionMonitor.enabled}
          onChange={() => setConditionMonitor({ ...conditionMonitor, enabled: !conditionMonitor.enabled })}
          formattedLabel={<span className="semi-bold">{t('NOTIFICATIONS.MODAL.ENABLED')}</span>}
        />
      )}
      {!isEditMode && (
        <strong>
          {conditionMonitor.enabled ? t('NOTIFICATIONS.MODAL.ENABLED') : t('NOTIFICATIONS.MODAL.DISABLED')}
        </strong>
      )}
    </div>
  );

  const nameSection = (
    <>
      {!isEditMode && (
        <div className="mb7">
          <label className="col-form-label">{t('NOTIFICATIONS.MODAL.NOTIFICATION_NAME')}</label>
          <div>{conditionMonitor.name}</div>
        </div>
      )}
      {isEditMode && (
        <div className="mb7">
          <label className="col-form-label">{t('NOTIFICATIONS.MODAL.NOTIFICATION_NAME')}</label>
          <Icon
            icon="fa-info-circle"
            testId="title-tooltip"
            extraClassNames="ml5"
            tooltip={t('NOTIFICATIONS.MODAL.TOOLTIP.EMAIL_SUBJECT', { name: conditionMonitor.name })}
            tooltipPlacement="right"
          />
          <FormInput
            value={conditionMonitor.name}
            onChange={(event) => {
              setConditionMonitor({ ...conditionMonitor, name: event.currentTarget.value });
            }}
            placeholder={t('NOTIFICATIONS.MODAL.NOTIFICATION_NAME_PLACEHOLDER')}
            data-testid="nameInput"
          />
        </div>
      )}
    </>
  );

  const checkboxSection = (
    <div className="mb7">
      <label className="col-form-label">{t('NOTIFICATIONS.MODAL.INCLUDE_CAPSULE_PROPERTIES')}</label>
      {isEditMode && editableCapsulePropertiesList}
      {!isEditMode && readOnlyCapsulePropertiesList}
    </div>
  );

  const customizeEmailSectionContents = (
    <div className="mb7">
      <label className="col-form-label">{t('NOTIFICATIONS.MODAL.CUSTOMIZE_EMAIL')}</label>
      {isEditMode && (
        <Icon
          icon="fa-info-circle"
          testId="title-tooltip"
          extraClassNames="ml5"
          tooltip={t('NOTIFICATIONS.MODAL.TOOLTIP.CUSTOMIZE_EMAIL')}
        />
      )}
      <div className="classicEditor" data-testid="customizeEmailEditor">
        <CKEditor
          id={conditionMonitor.id}
          document={notificationConfiguration.contextualText}
          toolbar={toolbar}
          plugins={plugins}
          canEdit={isEditMode}
          afterOnInit={_.noop}
          onDestroy={_.noop}
          documentChanged={documentChanged}
          domId="notificationEditor"
          shouldFocusOnInit={false}
        />
      </div>
    </div>
  );

  const itemFinderWarningContents = itemFinderWarnings && isItemFinderEnabled && (
    <Alert
      className="p5 max-height-100 overflowAuto mt5 mb0"
      transition={false}
      variant="warning"
      data-testid="finder-warnings">
      {itemFinderWarnings}
    </Alert>
  );

  const advancedSectionContents = (
    <div id="advancedScheduleOptions" className="ml20 mr10">
      <div id="lookAhead" className="mb7" data-testid="lookAheadWrapper">
        <label className="col-form-label">{t('NOTIFICATIONS.MODAL.LOOK_AHEAD.LABEL')}</label>
        <div className="text-italic mb7" data-testid="lookAheadHelp">
          {t('NOTIFICATIONS.MODAL.LOOK_AHEAD.HELP')}
        </div>
        <ValueWithUnits
          propName="lookAhead"
          onChange={setLookAheadAndConditionMonitor}
          min={0}
          availableUnits={DURATION_TIME_UNITS}
          required={false}
          defaultValue={lookAhead}
        />
      </div>

      {/* Timezone */}
      <div className="mb7">
        <label className="col-form-label">{t('NOTIFICATIONS.MODAL.TIMEZONE')}</label>
        <Icon
          icon="fa-info-circle"
          testId="title-tooltip"
          extraClassNames="ml5"
          tooltip={t('NOTIFICATIONS.MODAL.TOOLTIP.TIMEZONE')}
        />
        <TimeZoneSelector
          extraClassNames="width-220"
          timezone={timezone}
          disabled={!isEditMode}
          onSelect={(timezone) => {
            setNotificationConfiguration({
              ...notificationConfiguration,
              timezone: timezone.name,
            });
          }}
        />
      </div>

      {/* Email grouping */}
      <div className="flexColumnContainer flexAlignCenter">
        <Checkbox
          id="capsuleGrouping"
          classes="cursorPointer m5"
          value={CapsuleGroupingEnum.ALL}
          label="NOTIFICATIONS.MODAL.EMAIL_OPTIONS.GROUP_INTO_ONE"
          isChecked={isCapsuleGroupingChecked()}
          skipMemo={true}
          onClick={() => {
            setNotificationConfiguration({
              ...notificationConfiguration,
              capsuleGrouping:
                notificationConfiguration.capsuleGrouping === CapsuleGroupingEnum.ALL
                  ? CapsuleGroupingEnum.CAPSULE
                  : CapsuleGroupingEnum.ALL,
            });
          }}
        />
        <Icon
          icon="fa-info-circle"
          testId="title-tooltip"
          extraClassNames="ml5"
          tooltip={t('NOTIFICATIONS.MODAL.TOOLTIP.GROUP_CHECKBOX')}
        />
      </div>

      {/* Select asset for item finder */}
      {conditionMonitor.swapSource && (
        <>
          <div className="m5">
            <Checkbox
              id="conditionAcrossAssets"
              label={
                <TranslationWithHTML
                  translationKey="NOTIFICATIONS.MODAL.ACROSS_ASSETS"
                  translationParams={{
                    conditionIcon: "<i class='fc fc-capsule-set sq-icon-color'></i>",
                    assetIcon: "<i class='fa fa-cube sq-icon-color'></i>",
                    conditionName: `<strong>${conditionMonitor.swapSource.condition.name}</strong>`,
                    assetName: `<strong>${
                      isItemFinderEnabled ? itemFinderAsset.name : conditionMonitor.swapSource.asset.name
                    }</strong>`,
                  }}
                />
              }
              isChecked={isItemFinderEnabled}
              skipMemo={true}
              onClick={() =>
                isItemFinderEnabled
                  ? setItemFinderAsset(undefined)
                  : setItemFinderAsset(conditionMonitor.swapSource!.asset)
              }
            />
          </div>
          <FakeLink onClick={editAsset} extraClassNames="ml25">
            {t('NOTIFICATIONS.MODAL.ACROSS_ASSETS_CHANGE')}
          </FakeLink>
          {itemFinderWarningContents}
        </>
      )}
      {!conditionMonitor.swapSource && (
        <div className="mb7">{t('NOTIFICATIONS.MODAL.ACROSS_ASSETS_NOT_SUPPORTED')}</div>
      )}
    </div>
  );

  const advancedSection = (
    <>
      {isEditMode && (
        <div className="form-group mb0">
          <div
            className="flexColumnContainer flexSpaceBetween mt5 cursorPointer noOutline"
            data-testid="notificationAdvanceSectionButton"
            onClick={() => setAdvancedSectionExpanded(!advancedSectionExpanded)}
            onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && setAdvancedSectionExpanded(!advancedSectionExpanded)}>
            <AdvancedSection
              collapsed={!advancedSectionExpanded}
              t={t}
              alternateName={'NOTIFICATIONS.MODAL.ADVANCED'}
            />
          </div>
          <Collapse in={advancedSectionExpanded} unmountOnExit={true}>
            {advancedSectionContents}
          </Collapse>
        </div>
      )}

      {!isEditMode && (
        <>
          <div className="mb7">
            <label className="col-form-label">{t('NOTIFICATIONS.MODAL.LOOK_AHEAD.LABEL')}</label>
            <div>
              {lookAhead.value} {t(getReadableUnits(lookAhead.units))}
            </div>
          </div>

          <div className="mb7">
            <label className="col-form-label">{t('NOTIFICATIONS.MODAL.TIMEZONE')}</label>
            <div>{timezone.name}</div>
          </div>

          <div className="mb7">
            <div>
              {isCapsuleGroupingChecked() && (
                <>
                  <Icon icon="fa-check" extraClassNames="link-black mr5" />
                  {t('NOTIFICATIONS.MODAL.EMAIL_OPTIONS.GROUP_INTO_ONE')}
                </>
              )}
              {!isCapsuleGroupingChecked() && <>{t('NOTIFICATIONS.MODAL.EMAIL_OPTIONS.EMAIL_PER_CAPSULE')}</>}
            </div>
          </div>

          {conditionMonitor.swapSource && !_.isNil(itemFinderAsset) && (
            <div className="mb7">
              <Icon icon="fa-check" extraClassNames="link-black mr5" />
              <TranslationWithHTML
                translationKey="NOTIFICATIONS.MODAL.ACROSS_ASSETS_OVERVIEW"
                translationParams={{
                  conditionIcon: "<i class='fc fc-capsule-set sq-icon-color'></i>",
                  assetIcon: "<i class='fa fa-cube sq-icon-color'></i>",
                  conditionName: `<strong>${conditionMonitor.swapSource.condition.name}</strong>`,
                  assetName: `<strong>${itemFinderAsset.name}</strong>`,
                  count: conditionMonitor.conditionIds.length,
                }}
              />
              {itemFinderWarningContents}
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      {topSection}
      {nameSection}
      {customizeEmailSectionContents}
      {checkboxSection}
      <EmailRecipientsSection
        notificationConfiguration={notificationConfiguration}
        onSetIdentities={(type, identities) => {
          setNotificationConfiguration({
            ...notificationConfiguration,
            [type]: identitiesToRecipients(identities),
          });
        }}
        isEditMode={isEditMode}
      />
      {advancedSection}
    </>
  );
};
