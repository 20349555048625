import _ from 'lodash';
import { useState } from 'react';
import { TableSort } from '@/notifications/notification.types';
import { AnyProperty } from '@/utilities.types';

interface UseTableFilterSortParams<T> {
  defaultFilters?: T;
  defaultSort?: TableSort;
  extraFilters?: AnyProperty;
}

const isEmptyValue = <T>(value: T) => typeof value !== 'boolean' && _.isEmpty(value);

export const useTableFilterSort = <T extends object>({
  defaultFilters,
  defaultSort,
  extraFilters,
}: UseTableFilterSortParams<T>) => {
  const [searchParams, setSearchParams] = useState(defaultFilters ?? {});
  const [sort, setSort] = useState(defaultSort ?? { sortProperty: '' });

  const onSort = (field: string, order: boolean) => setSort({ sortProperty: field, sortAscending: !order });

  const onFilter = (field: string, value: number | string) =>
    setSearchParams((searchParams) => ({ ...searchParams, [field]: value }));

  const hasSearchParams = () =>
    _.some(searchParams, _.negate(isEmptyValue)) || _.some(extraFilters, _.negate(isEmptyValue));

  return { searchParams, sort, onFilter, onSort, hasSearchParams };
};
