import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TextButton } from '@/core/TextButton.atom';
import { Alert, Modal } from 'react-bootstrap';
import { NotificationsDetails } from '@/notifications/NotificationsDetails';
import { doTrack } from '@/track/track.service';
import { errorToast, successToast } from '@/utilities/toast.utilities';
import {
  allEmailsValid,
  ConditionMonitorNotification,
  ConditionMonitorOutputWithNames,
  fetchConditionMonitor,
  getItemFinderInput,
  hasRecipients,
  newConditionMonitorNotificationWithSwapSource,
  recipientsToInput,
  saveConditionMonitorNotificationConfiguration,
  saveNotificationFieldTracking,
  updateNotificationFieldTracking,
  validateEmails,
} from '@/notifications/notifications.utilities';
import { TooltipButton } from '@/core/TooltipButton.atom';
import {
  ConditionMonitorInputV1,
  ConditionMonitorNotificationConfigurationInputV1,
  ItemFinderOutputV1,
  ItemPreviewV1,
  NotificationConfigurationOutputV1,
  sqConditionMonitorsApi,
} from '@/sdk';
import { formatApiError, isViewOnlyWorkbookMode } from '@/utilities/utilities';
import { TItemProperties } from '@/tools/itemProperties/hooks/useProperties';
import { ConfirmDeleteModal } from '@/core/ConfirmDeleteModal.molecule';
import { Icon } from '@/core/Icon.atom';
import { NotificationEditingContext } from '@/notifications/notifications.constants';
import { updateConditionNotificationIds } from '@/notifications/notifications.actions';
import { sqWorkbenchStore } from '@/core/core.stores';
import { LiveUpdateRate, ScheduleTypeName, UpdatedSchedule } from '@/schedule/schedule.types';
import { DURATION_TIME_UNITS } from '@/main/app.constants';
import { Schedule } from '@/schedule/Schedule';
import { getNewCronSchedule, isScheduleValid } from '@/schedule/schedule.utilities';
import { validateItemHasAtLeastOneAssetChild } from '@/utilities/assetTree.utilities';
import { SelectAssetSearchWidget, SelectedAsset } from '@/core/SelectAssetSearchWidget.molecule';
import { useAsyncEffect } from 'rooks';
import { TranslationWithHTML } from '@/core/ContainerWithHTML.atom';
import { getAssetPathFromAncestors } from '@/utilities/formula.utilities';

const DEFAULT_LIVE_RATE: LiveUpdateRate = {
  unit: DURATION_TIME_UNITS.find(({ translationKey }) => translationKey === 'DURATION_UNITS.MINUTES')!,
  value: 15,
};

export interface NotificationsConfigureModalProps {
  conditionMonitorId?: string;
  condition?: TItemProperties;
  closeModal: (refresh?: boolean) => void;
  context: NotificationEditingContext;
}

export const NotificationsConfigureModal: React.FunctionComponent<NotificationsConfigureModalProps> = ({
  conditionMonitorId,
  condition,
  closeModal,
  context,
}) => {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState(false);
  const [conditionMonitor, setConditionMonitor] = useState<ConditionMonitorOutputWithNames>();
  const conditionMonitorUnmodified = useRef<ConditionMonitorOutputWithNames>();

  const [notificationConfiguration, setNotificationConfiguration] = useState<NotificationConfigurationOutputV1>();
  const [newCronSchedule, setNewCronSchedule] = useState<string[]>();
  const notificationConfigurationUnmodified = useRef<NotificationConfigurationOutputV1>();

  const [itemFinderAsset, setItemFinderAsset] = useState<SelectedAsset>();
  const [updatedItemFinderAsset, setUpdatedItemFinderAsset] = useState<SelectedAsset>();
  const [itemFinder, setItemFinder] = useState<ItemFinderOutputV1>();
  const [isEditingItemFinderAsset, setEditingItemFinderAsset] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [isEditingSchedule, setEditingSchedule] = useState(false);
  const [updatedSchedule, setUpdatedSchedule] = useState<UpdatedSchedule>();
  const [advancedSectionExpanded, setAdvancedSectionExpanded] = useState(false);

  const isNew = _.isEmpty(conditionMonitor?.id);
  const [isEditMode, setIsEditMode] = useState(_.isEmpty(conditionMonitorId));

  const saveButtonText = isNew ? 'NOTIFICATIONS.MODAL.CREATE_NOTIFICATION' : 'SAVE';
  const saveButtonTooltipText = isNew ? 'NOTIFICATIONS.MODAL.TOOLTIP.SAVE' : 'NOTIFICATIONS.MODAL.TOOLTIP.UPDATE';
  const canSave =
    !isSaving &&
    !_.isEmpty(conditionMonitor?.name) &&
    hasRecipients(notificationConfiguration) &&
    allEmailsValid(validateEmails(notificationConfiguration));

  useAsyncEffect(async () => {
    let fetcher: () => Promise<ConditionMonitorNotification>;
    if (conditionMonitorId) {
      fetcher = async () => await fetchConditionMonitor(conditionMonitorId);
    } else if (condition) {
      fetcher = async () => await newConditionMonitorNotificationWithSwapSource(condition);
    } else {
      throw TypeError('Must provide condition or condition monitor id');
    }

    try {
      const { conditionMonitor, notificationConfiguration, itemFinder } = await fetcher();
      setConditionMonitor(conditionMonitor);
      conditionMonitorUnmodified.current = conditionMonitor;
      setNotificationConfiguration(notificationConfiguration);
      notificationConfigurationUnmodified.current = notificationConfiguration;
      setItemFinder(itemFinder);
      if (conditionMonitor.swapSource && itemFinder?.enabled) {
        setItemFinderAsset(conditionMonitor.swapSource.asset);
      }
    } catch (e) {
      errorToast({ httpResponseOrError: e, displayForbidden: true });
      closeModal();
    }
  }, []);

  const onClickSave = async () => {
    if (isEditingSchedule) {
      if (updatedSchedule) {
        setNewCronSchedule(getNewCronSchedule(updatedSchedule));
      }

      return setEditingSchedule(false);
    }

    if (isEditingItemFinderAsset) {
      if (updatedItemFinderAsset) {
        setItemFinderAsset({
          id: updatedItemFinderAsset.id,
          name: updatedItemFinderAsset.ancestors
            ? getAssetPathFromAncestors(
                updatedItemFinderAsset.ancestors.concat(updatedItemFinderAsset as ItemPreviewV1),
              )
            : updatedItemFinderAsset.name,
        });
      }

      return setEditingItemFinderAsset(false);
    }

    if (
      !conditionMonitor ||
      !notificationConfiguration ||
      !conditionMonitorUnmodified.current ||
      !notificationConfigurationUnmodified.current
    ) {
      return;
    }

    isNew
      ? saveNotificationFieldTracking(conditionMonitor, notificationConfiguration)
      : updateNotificationFieldTracking(
          conditionMonitor,
          conditionMonitorUnmodified.current,
          notificationConfiguration,
          notificationConfigurationUnmodified.current,
        );

    const { itemFinderInput, conditionIds } = getItemFinderInput(conditionMonitor, itemFinder, itemFinderAsset);
    const conditionMonitorInput: ConditionMonitorInputV1 = {
      name: conditionMonitor.name,
      conditionIds: _.isNil(conditionIds) ? conditionMonitor.conditionIds : conditionIds,
      queryRangeLookAhead: conditionMonitor.queryRangeLookAhead,
      cronSchedule: newCronSchedule ?? conditionMonitor.cronSchedule ?? [],
      enabled: conditionMonitor.enabled,
      itemFinderId: conditionMonitor.itemFinderId,
    };
    const notificationConfigurationInput: ConditionMonitorNotificationConfigurationInputV1 = {
      toEmailRecipients: recipientsToInput(notificationConfiguration.toEmailRecipients),
      ccEmailRecipients: recipientsToInput(notificationConfiguration.ccEmailRecipients),
      bccEmailRecipients: recipientsToInput(notificationConfiguration.bccEmailRecipients),
      capsuleGrouping: notificationConfiguration.capsuleGrouping,
      capsuleProperties: notificationConfiguration.capsuleProperties,
      contextualText: notificationConfiguration.contextualText,
      timezone: notificationConfiguration.timezone,
    };

    setIsSaving(true);
    try {
      await saveConditionMonitorNotificationConfiguration(
        conditionMonitorInput,
        notificationConfigurationInput,
        itemFinderInput,
        conditionMonitor.id,
        itemFinder?.id,
      );
      successToast({
        messageKey:
          itemFinderInput?.enabled && !_.isNil(itemFinderAsset)
            ? 'NOTIFICATIONS.MODAL.SAVED_WITH_ASSET'
            : 'NOTIFICATIONS.MODAL.SAVED',
        messageParams: {
          name: itemFinderInput?.enabled && !_.isNil(itemFinderAsset) ? itemFinderAsset.name : undefined,
        },
        buttonLabelKey: 'NOTIFICATIONS_MANAGEMENT.TITLE',
        buttonAction: () => window.open('/notifications-management', 'SeeqNotificationsManagement'),
        buttonIcon: 'fc-bell-regular',
      });
      closeModal(true);
    } catch (e) {
      setErrorMessage(_.get(e, 'response.data.statusMessage', formatApiError(e as Error)));
    } finally {
      setIsSaving(false);
    }
  };

  const onClickDelete = async () => {
    if (isNew || !conditionMonitor) {
      return;
    }

    await sqConditionMonitorsApi.archiveConditionMonitor({ id: conditionMonitor.id });
    updateConditionNotificationIds(conditionMonitor.conditionIds);
    closeModal(true);
    successToast({
      messageKey: 'TRASH.ITEM_TRASHED_NOTIFICATION',
      messageParams: { ITEM_NAME: conditionMonitor.name },
    });
    doTrack('Notification', 'Existing notification', 'Deleted');
  };

  const toggleEditMode = () => setIsEditMode(!isEditMode);

  const modalTitle = () => {
    if (isEditMode) {
      if (isEditingSchedule) {
        return t('NOTIFICATIONS.MODAL.CHANGE_SCHEDULE');
      } else if (isEditingItemFinderAsset) {
        return t('NOTIFICATIONS.MODAL.ACROSS_ASSETS_CHANGE');
      } else if (isNew) {
        return (
          <>
            {t('NOTIFICATIONS.MODAL.HEADER_CREATE')}
            <TooltipButton
              tooltipKBLink="https://support.seeq.com/space/KB/2594111502/Notifications+on+Conditions"
              tooltipText={t('NOTIFICATIONS.MODAL.TOOLTIP.CLICK_FOR_INFO')}
            />
          </>
        );
      } else {
        return t('NOTIFICATIONS.MODAL.HEADER_EDIT');
      }
    }

    return (
      <div className="flexColumnContainer">
        {t('NOTIFICATIONS.MODAL.HEADER_DETAILS')}
        {!isViewOnlyWorkbookMode() && (
          <div className="ml15 cursorPointer" data-testid="edit-notification-pencil" onClick={toggleEditMode}>
            <Icon icon="fa-pencil" extraClassNames="mr10" />
          </div>
        )}
      </div>
    );
  };

  const modalHeader = () => (
    <div className="flexColumnContainer flexFill flexSpaceBetween">
      <div className="flexColumnContainer">
        <Modal.Title>{modalTitle()}</Modal.Title>
      </div>
    </div>
  );

  const modalBody = () => {
    if (isEditingSchedule && conditionMonitor) {
      return (
        <Schedule
          cronSchedule={conditionMonitor.cronSchedule}
          initialScheduleType={ScheduleTypeName.LIVE}
          defaultLiveRate={DEFAULT_LIVE_RATE}
          updatedSchedule={updatedSchedule}
          onScheduleChange={setUpdatedSchedule}
        />
      );
    }

    if (isEditingItemFinderAsset && conditionMonitor) {
      return (
        <>
          <Alert className="p5" variant="info" transition={false}>
            <TranslationWithHTML
              translationKey="NOTIFICATIONS.MODAL.ACROSS_ASSETS_HELP"
              translationParams={{
                icon: "<i class='fc sq-icon-color fc-capsule-set'></i>",
                name: `<strong>${conditionMonitor.swapSource!.condition.name}</strong>`,
              }}
            />
          </Alert>
          <SelectAssetSearchWidget
            onSelect={(item) => setUpdatedItemFinderAsset(item)}
            iconPartialTooltipKey="TABLE_BUILDER"
            assetId={updatedItemFinderAsset?.id ?? itemFinderAsset?.id ?? conditionMonitor.swapSource?.asset?.id}
            validateAsset={validateItemHasAtLeastOneAssetChild}
            scopeIds={[sqWorkbenchStore.stateParams.workbookId]}
          />
        </>
      );
    }

    return (
      <>
        {errorMessage && (
          <Alert className="p10 mb10" transition={false} variant="danger">
            {errorMessage}
          </Alert>
        )}
        {conditionMonitor && notificationConfiguration && (
          <NotificationsDetails
            isEditMode={isEditMode}
            conditionMonitor={conditionMonitor}
            notificationConfiguration={notificationConfiguration}
            setConditionMonitor={setConditionMonitor}
            setNotificationConfiguration={setNotificationConfiguration}
            context={context}
            editSchedule={() => setEditingSchedule(true)}
            newCronSchedule={newCronSchedule}
            editAsset={() => {
              setEditingItemFinderAsset(true);
              setUpdatedItemFinderAsset(undefined);
            }}
            setItemFinderAsset={setItemFinderAsset}
            itemFinderAsset={itemFinderAsset}
            itemFinderWarnings={itemFinder?.lastRunWarnings.join(', ')}
            setAdvancedSectionExpanded={setAdvancedSectionExpanded}
            advancedSectionExpanded={advancedSectionExpanded}
          />
        )}
      </>
    );
  };

  const onBack = () => {
    if (isEditingSchedule) {
      setEditingSchedule(false);
    } else if (isEditingItemFinderAsset) {
      setEditingItemFinderAsset(false);
    }
  };

  const modalFooter = () => (
    <>
      {!isNew && isEditMode && !isEditingSchedule && !isEditingItemFinderAsset && (
        <TextButton
          testId="deleteButton"
          extraClassNames="mr15"
          variant="danger"
          onClick={() => setShowConfirmDeleteModal(true)}
          label="DELETE"
        />
      )}

      {(isEditingSchedule || isEditingItemFinderAsset) && (
        <TextButton testId="cancelButton" extraClassNames="btn btn-default" onClick={onBack} label="BACK" />
      )}

      <div className="flexFill" />

      {isEditMode && (
        <>
          {!isEditingSchedule && !isEditingItemFinderAsset && (
            <TextButton testId="cancelButton" extraClassNames="btn btn-default" onClick={closeModal} label="CANCEL" />
          )}
          <TextButton
            label={isEditingSchedule || isEditingItemFinderAsset ? t('DONE') : saveButtonText}
            testId="saveOrNextButton"
            variant="theme"
            onClick={onClickSave}
            disabled={
              (!isEditingSchedule && !canSave) ||
              (isEditingSchedule && !isScheduleValid(updatedSchedule)) ||
              (isEditingItemFinderAsset && _.isNil(updatedItemFinderAsset))
            }
            iconStyle="inherit"
            tooltip={saveButtonTooltipText}
            tooltipOptions={{ placement: 'top' }}
          />
        </>
      )}
      {!isEditMode && (
        <TextButton
          label={t('CLOSE')}
          testId="closeButton"
          extraClassNames="ml15"
          variant="theme"
          onClick={closeModal}
          iconStyle="inherit"
          tooltipOptions={{ placement: 'top' }}
        />
      )}
    </>
  );

  return (
    <>
      <Modal
        show={true}
        onHide={closeModal}
        animation={false}
        scrollable={true}
        data-testid="notificationsModalConfigure"
        // Allows CKEditor to work in the modal (https://github.com/ckeditor/ckeditor5/issues/1409)
        enforceFocus={false}>
        <Modal.Header closeButton={true}>{modalHeader()}</Modal.Header>

        <Modal.Body>{modalBody()}</Modal.Body>

        <Modal.Footer>{modalFooter()}</Modal.Footer>
      </Modal>

      {showConfirmDeleteModal && (
        <ConfirmDeleteModal onClose={() => setShowConfirmDeleteModal(false)} action={onClickDelete} />
      )}
    </>
  );
};
