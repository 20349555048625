import { IdentityOption, SelectIdentity } from '@/core/SelectIdentity.molecule';
import React from 'react';

export type RecipientsType = 'toEmailRecipients' | 'ccEmailRecipients' | 'bccEmailRecipients';

interface EmailRecipientsInputProps {
  type: RecipientsType;
  onSetIdentities: (identities: IdentityOption[]) => void;
  identities: IdentityOption[];
  isInvalid?: boolean;
}

export const EmailRecipientsInput: React.FunctionComponent<EmailRecipientsInputProps> = ({
  type,
  identities,
  onSetIdentities,
  isInvalid,
}) => {
  return (
    <div className={type === 'toEmailRecipients' ? 'multiSelectLarge' : 'multiSelectTwoLine'}>
      <SelectIdentity
        idForLabel="identityName"
        setIdentity={onSetIdentities}
        identity={identities}
        includeAllProperties={true}
        startEditable={true}
        placeholder="NOTIFICATIONS.MODAL.EMAIL_INPUT.PLACEHOLDER"
        multiple={true}
        clearIdentityWhenEmpty={true}
        allowNew={true}
        isInvalid={isInvalid}
        allowGroups={true}
      />
    </div>
  );
};
