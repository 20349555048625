import React from 'react';
import { EmptyTable } from '@/notifications/management/EmptyTable';

export const EmptyConditionMonitorTable = () => (
  <EmptyTable
    testId="conditionMonitorTableEmpty"
    header="NOTIFICATIONS_MANAGEMENT.CONDITION_MONITOR.EMPTY.HEADER"
    body="NOTIFICATIONS_MANAGEMENT.CONDITION_MONITOR.EMPTY.BODY"
    linkText="NOTIFICATIONS_MANAGEMENT.CONDITION_MONITOR.EMPTY.ACTION"
    linkUrl="https://support.seeq.com/space/KB/2594111502"
  />
);
