import _ from 'lodash';
import {
  ConditionMonitorInputV1,
  ConditionMonitorNotificationConfigurationInputV1,
  ConditionMonitorOutputV1,
  EmailNotificationRecipientOutputV1,
  ItemFinderInputV1,
  ItemFinderOutputV1,
  NotificationConfigurationOutputV1,
  sqConditionMonitorsApi,
  sqItemsApi,
  sqNotificationConfigurationsApi,
} from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { sqWorkbenchStore, sqWorkbookStore, sqWorkstepsStore } from '@/core/core.stores';
import { CapsuleGroupingEnum } from '@/sdk/model/NotificationConfigurationOutputV1';
import { TItemProperties } from '@/tools/itemProperties/hooks/useProperties';
import { TREND_COLUMNS } from '@/trendData/trendData.constants';
import { getViewWorksheetLink } from '@/main/routing.utilities';
import { doTrack } from '@/track/track.service';
import { IdentityOption } from '@/core/SelectIdentity.molecule';
import i18next from 'i18next';
import { updateConditionNotificationIds } from '@/notifications/notifications.actions';
import { FinderTypeEnum } from '@/sdk/model/ItemFinderConfigurationV1';
import { AxiosResponse } from 'axios';
import { SelectedAsset } from '@/core/SelectAssetSearchWidget.molecule';
import { getAssetPath, getDependencies } from '@/utilities/formula.utilities';
import { TrackInformation } from '@/track/track.types';

export interface ConditionMonitorSwapSource {
  condition: Record<'id' | 'name', string>;
  asset: Record<'id' | 'name', string>;
}

export interface ConditionMonitorOutputWithNames extends ConditionMonitorOutputV1 {
  swapSource?: ConditionMonitorSwapSource;
  conditionNames?: string[];
}

export interface ConditionMonitorNotification {
  conditionMonitor: ConditionMonitorOutputWithNames;
  notificationConfiguration: NotificationConfigurationOutputV1;
  itemFinder?: ItemFinderOutputV1;
}

const MAX_CONDITION_NAMES = 10;

const DEFAULT_CAPSULE_PROPERTIES = [
  _.find(TREND_COLUMNS, { key: 'startTime' })!,
  _.find(TREND_COLUMNS, { key: 'endTime' })!,
  _.find(TREND_COLUMNS, { key: 'duration' })!,
];

const DEFAULT_CONDITION_PROPERTIES = [
  _.find(TREND_COLUMNS, { key: 'asset' })!,
  _.find(TREND_COLUMNS, { key: 'fullpath' })!,
];

export const DEFAULT_PROPERTIES = DEFAULT_CAPSULE_PROPERTIES.concat(DEFAULT_CONDITION_PROPERTIES);

export function getDefaultContextUrl(): string {
  return getViewWorksheetLink(
    sqWorkbenchStore.stateParams.workbookId,
    sqWorkbenchStore.stateParams.worksheetId,
    undefined,
    sqWorkstepsStore.current.id,
    false,
  );
}

function getWorksheetAndWorkbookName(): string {
  return `${sqWorkbookStore.name} - ${sqWorkbookStore.getWorksheetName(sqWorkbenchStore.stateParams.worksheetId)}`;
}

export function newConditionMonitorNotification(
  condition: TItemProperties,
  includeAssetProperties: boolean,
  swapSource?: ConditionMonitorSwapSource,
): ConditionMonitorNotification {
  const defaultProperties = includeAssetProperties
    ? DEFAULT_CAPSULE_PROPERTIES.concat(DEFAULT_CONDITION_PROPERTIES)
    : DEFAULT_CAPSULE_PROPERTIES;
  return {
    conditionMonitor: {
      id: '',
      conditionIds: [condition.id],
      enabled: true,
      name: `${condition.name}`,
      createdAt: '',
      executorId: '',
      updatedAt: '',
      queryRangeLookAhead: '0',
      type: SeeqNames.Types.ConditionMonitor,
      conditionNames: [condition.name],
      swapSource,
    },
    notificationConfiguration: {
      capsuleProperties: _.map(defaultProperties, (p) => p.propertyName as string),
      capsuleGrouping: CapsuleGroupingEnum.ALL,
      timezone: sqWorkbenchStore.timezone.name,
      contextualText: `<p>${i18next.t('NOTIFICATIONS.MODAL.CUSTOMIZE_EMAIL_SEE_MORE_LINK', {
        link: `<a href="${getDefaultContextUrl()}">${getWorksheetAndWorkbookName()}</a>`,
      })}</p>`,
      toEmailRecipients: [
        {
          identityId: sqWorkbenchStore.currentUser.id,
          name: sqWorkbenchStore.currentUser.name,
        },
      ],
      ccEmailRecipients: [],
      bccEmailRecipients: [],
    },
  };
}

export async function newConditionMonitorNotificationWithSwapSource(
  condition: TItemProperties,
): Promise<ConditionMonitorNotification> {
  let includeAssetProperties = false;
  const swapSource = await getSwapSourceForCondition(condition.id, condition.name, (hasAssets) => {
    includeAssetProperties = hasAssets;
  });
  return newConditionMonitorNotification(condition, includeAssetProperties, swapSource);
}

export async function fetchConditionMonitor(conditionMonitorId: string): Promise<ConditionMonitorNotification> {
  const { data: conditionMonitor }: AxiosResponse<ConditionMonitorOutputWithNames> =
    await sqConditionMonitorsApi.getConditionMonitor({ id: conditionMonitorId });
  const { data: notificationConfiguration } = await sqNotificationConfigurationsApi.getNotificationConfiguration({
    id: conditionMonitorId,
  });

  let itemFinder: ItemFinderOutputV1 | undefined;
  if (conditionMonitor.itemFinderId) {
    itemFinder = (await sqItemsApi.getItemFinder({ id: conditionMonitor.itemFinderId })).data;
    const finderConfig = _.find(
      itemFinder.finderConfigurations,
      (config) => config.finderType === FinderTypeEnum.SWAPACROSSASSETS && config.isInclude,
    );
    if (finderConfig) {
      conditionMonitor.swapSource = {
        condition: { id: finderConfig.swapItemId!, name: await getConditionName(finderConfig.swapItemId!) },
        asset: { id: finderConfig.rootAssetId!, name: await getAssetPath({ id: finderConfig.rootAssetId! }) },
      };
      conditionMonitor.conditionNames = [conditionMonitor.swapSource.condition.name];
    }
  }

  if (_.isNil(conditionMonitor.conditionNames) && conditionMonitor.conditionIds.length <= MAX_CONDITION_NAMES) {
    conditionMonitor.conditionNames = await _.chain(conditionMonitor.conditionIds)
      .take(MAX_CONDITION_NAMES)
      .map(async (id) => await getConditionName(id))
      .thru((promises) => Promise.all(promises))
      .value();
  }

  if (_.isNil(conditionMonitor.swapSource) && conditionMonitor.conditionIds.length === 1) {
    conditionMonitor.swapSource = await getSwapSourceForCondition(
      conditionMonitor.conditionIds[0],
      conditionMonitor.conditionNames![0],
    );
  }

  return {
    conditionMonitor,
    notificationConfiguration,
    itemFinder,
  };
}

async function getSwapSourceForCondition(
  conditionId: string,
  conditionName: string,
  callbackWithAssets: (hasAssets: boolean) => void = () => {},
): Promise<ConditionMonitorSwapSource | undefined> {
  const { assets } = await getDependencies({ id: conditionId });
  callbackWithAssets(assets.length > 0);
  if (assets.length === 1) {
    const assetId = _.takeRight(assets[0].pathComponentIds, 2)[0] as string;
    return {
      condition: { id: conditionId, name: conditionName },
      asset: { id: assetId, name: await getAssetPath({ id: assetId }) },
    };
  }
}

async function getConditionName(id: string): Promise<string> {
  return (await sqItemsApi.getProperty({ id, propertyName: SeeqNames.Properties.Name })).data.value!;
}

export async function saveConditionMonitorNotificationConfiguration(
  conditionMonitorInput: ConditionMonitorInputV1,
  notificationConfigurationInput: ConditionMonitorNotificationConfigurationInputV1,
  itemFinderInput?: ItemFinderInputV1,
  id?: string,
  itemFinderId?: string,
): Promise<NotificationConfigurationOutputV1> {
  if (itemFinderInput) {
    const { data: savedItemFinder } = itemFinderId
      ? await sqItemsApi.updateItemFinder(itemFinderInput, { id: itemFinderId })
      : await sqItemsApi.createItemFinder(itemFinderInput);
    conditionMonitorInput.itemFinderId = savedItemFinder.id;
  }

  const { data: savedConditionMonitor } = id
    ? await sqConditionMonitorsApi.updateConditionMonitor(conditionMonitorInput, { id })
    : await sqConditionMonitorsApi.createConditionMonitor(conditionMonitorInput);

  let config: NotificationConfigurationOutputV1;
  try {
    const { data } = await sqNotificationConfigurationsApi.setNotificationConfigurationForConditionMonitor(
      notificationConfigurationInput,
      { id: savedConditionMonitor.id },
    );
    config = data;
  } catch (e) {
    // If we were creating a new condition monitor, we need to guarantee that the caller can provide the newly
    // created condition monitor id for future calls to this function.
    if (!id) {
      sqConditionMonitorsApi.archiveConditionMonitor({ id: savedConditionMonitor.id });
    }

    throw e;
  }

  updateConditionNotificationIds(conditionMonitorInput.conditionIds);

  return config;
}

export function getItemFinderInput(
  conditionMonitor: ConditionMonitorOutputWithNames,
  itemFinder?: ItemFinderOutputV1,
  itemFinderAsset?: SelectedAsset,
): { itemFinderInput: ItemFinderInputV1 | undefined; conditionIds: string[] | undefined } {
  let itemFinderInput: ItemFinderInputV1 | undefined;
  let conditionIds: string[] | undefined;
  if (conditionMonitor.swapSource) {
    if (itemFinder) {
      const isItemFinderRemoved = _.isNil(itemFinderAsset);
      if (isItemFinderRemoved) {
        // Revert to the original condition when removing the item finder
        conditionIds = [conditionMonitor.swapSource.condition.id];
      }

      itemFinderInput = {
        name: `Finder for Condition Monitor ${conditionMonitor.name}`,
        enabled: !isItemFinderRemoved,
        cronSchedule: itemFinder.cronSchedule,
        description: itemFinder.description,
        finderConfigurations: [
          {
            finderType: FinderTypeEnum.SWAPACROSSASSETS,
            isInclude: true,
            rootAssetId: itemFinderAsset?.id ?? conditionMonitor.swapSource.asset.id,
            swapItemId: conditionMonitor.swapSource.condition.id,
          },
        ],
      };
    } else if (itemFinderAsset) {
      itemFinderInput = {
        name: `Finder for Condition Monitor ${conditionMonitor.name}`,
        enabled: true,
        finderConfigurations: [
          {
            finderType: FinderTypeEnum.SWAPACROSSASSETS,
            isInclude: true,
            rootAssetId: itemFinderAsset.id,
            swapItemId: conditionMonitor.swapSource.condition.id,
          },
        ],
      };
    }
  }

  return { itemFinderInput, conditionIds };
}

export const readOnlyRecipientsList = (recipients: EmailNotificationRecipientOutputV1[]): string[] =>
  _.map(recipients, (recipient) => recipient.name ?? recipient.emailAddress ?? '');

export const recipientsToIdentities = (recipients: EmailNotificationRecipientOutputV1[]): IdentityOption[] =>
  _.map(recipients, (recipient) => ({
    id: recipient.identityId!,
    name: recipient.name ?? recipient.emailAddress ?? '',
    email: recipient.emailAddress,
  }));

export const identitiesToRecipients = (identities: IdentityOption[]): EmailNotificationRecipientOutputV1[] =>
  _.map(identities, (identity) =>
    identity.customOption
      ? { name: identity.label!, emailAddress: identity.label! }
      : { identityId: identity.id, name: identity.name },
  );

export const recipientsToInput = (recipients: EmailNotificationRecipientOutputV1[]): string[] =>
  _.map(recipients, (recipient) => recipient.identityId ?? recipient.emailAddress ?? recipient.name ?? '');

export function hasRecipients(notificationConfiguration?: NotificationConfigurationOutputV1): boolean {
  return !_.isEmpty(notificationConfiguration?.toEmailRecipients);
}

export function saveNotificationFieldTracking(
  conditionMonitor: ConditionMonitorOutputV1,
  notificationConfiguration: NotificationConfigurationOutputV1,
) {
  const { capsuleProperties, toEmailRecipients, ccEmailRecipients, bccEmailRecipients, capsuleGrouping } =
    notificationConfiguration;

  doTrack('Notification', 'Create', {
    enabled: conditionMonitor.enabled,
    capsulePropertyNames: capsuleProperties.toString(),
    capsulePropertiesCount: capsuleProperties.length,
    toEmails: toEmailRecipients.length,
    ccEmails: ccEmailRecipients.length,
    bccEmails: bccEmailRecipients.length,
    lookAheadSeconds: conditionMonitor.queryRangeLookAhead,
    capsuleGrouping,
  });
}

export function updateNotificationFieldTracking(
  conditionMonitor: ConditionMonitorOutputV1,
  conditionMonitorOriginal: ConditionMonitorOutputV1,
  notificationConfiguration: NotificationConfigurationOutputV1,
  notificationConfigurationOriginal: NotificationConfigurationOutputV1,
) {
  const trackInformation: TrackInformation = {};

  if (conditionMonitor.enabled !== conditionMonitorOriginal.enabled) {
    trackInformation.enabled = conditionMonitor.enabled;
  }

  if (conditionMonitor.name !== conditionMonitorOriginal.name) {
    trackInformation.name = 'Name';
  }

  if (notificationConfiguration.capsuleProperties !== notificationConfigurationOriginal.capsuleProperties) {
    trackInformation.capsulePropertyNames = notificationConfiguration.capsuleProperties.toString();
    trackInformation.capsulePropertiesCount = notificationConfiguration.capsuleProperties.length;
  }

  if (notificationConfiguration.contextualText !== notificationConfigurationOriginal.contextualText) {
    trackInformation.contextualText = 'Email Text';
  }

  if (!_.isEqual(notificationConfiguration.toEmailRecipients, notificationConfigurationOriginal.toEmailRecipients)) {
    trackInformation.toEmails = notificationConfiguration.toEmailRecipients.length;
  }

  if (
    notificationConfiguration?.ccEmailRecipients.length !== notificationConfigurationOriginal?.ccEmailRecipients.length
  ) {
    trackInformation.ccEmails = notificationConfiguration.ccEmailRecipients.length;
  }

  if (!_.isEqual(notificationConfiguration.bccEmailRecipients, notificationConfigurationOriginal.bccEmailRecipients)) {
    trackInformation.bccEmails = notificationConfiguration.bccEmailRecipients.length;
  }

  if (conditionMonitor.queryRangeLookAhead !== conditionMonitorOriginal.queryRangeLookAhead) {
    trackInformation.lookAheadSeconds = conditionMonitor.queryRangeLookAhead;
  }

  if (notificationConfiguration.timezone !== notificationConfigurationOriginal.timezone) {
    trackInformation.timezone = 'Timezone';
  }

  if (notificationConfiguration.capsuleGrouping !== notificationConfigurationOriginal.capsuleGrouping) {
    trackInformation.capsuleGrouping = notificationConfigurationOriginal.capsuleGrouping;
  }

  doTrack('Notification', 'Update', trackInformation);
}

export interface EmailValidity {
  to: boolean;
  cc: boolean;
  bcc: boolean;
}

function isValidEmail(email: string): boolean {
  return !!email
    .toLowerCase()
    // Simple match: check for [something]@[something].[something]
    // From
    // https://www.w3resource.com/javascript/form/email-validation.php#:~:text=To%20get%20a%20valid%20email,%5D%2B)*%24%2F
    .match(/^\S+@\S+\.\S+$/);
}

function isValidRecipient(recipient: EmailNotificationRecipientOutputV1): boolean {
  return !_.isEmpty(recipient.identityId ?? recipient.name) || isValidEmail(recipient.emailAddress ?? '');
}

export function validateEmails(
  notificationConfiguration?: Pick<
    NotificationConfigurationOutputV1,
    'toEmailRecipients' | 'ccEmailRecipients' | 'bccEmailRecipients'
  >,
): EmailValidity | undefined {
  if (notificationConfiguration === undefined) {
    return undefined;
  }
  const validator = (recipients: Array<EmailNotificationRecipientOutputV1>) =>
    _.every(recipients, (recipient) => isValidRecipient(recipient));

  return {
    to: validator(notificationConfiguration.toEmailRecipients),
    cc: validator(notificationConfiguration.ccEmailRecipients),
    bcc: validator(notificationConfiguration.bccEmailRecipients),
  };
}

export function allEmailsValid(emailValidity?: EmailValidity): boolean {
  return !!emailValidity && emailValidity.bcc && emailValidity.cc && emailValidity.to;
}
