import { sqWorkbenchStore } from '@/core/core.stores';
import { setCurrentUser } from '@/workbench/workbench.actions';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ConditionMonitorTable } from '@/notifications/management/ConditionMonitorTable';
import { TabTitle } from '@/core/TabTitle.atom';
import { useTranslation } from 'react-i18next';
import { ReportTable } from '@/notifications/management/ReportTable';
import { isConditionMonitorNotificationsEnabled } from '@/services/systemConfiguration.utilities';

export const NotificationsManagement = () => {
  const { t } = useTranslation();

  const notificationTabs = _.compact([
    isConditionMonitorNotificationsEnabled() && {
      id: 'conditionMonitors',
      title: 'NOTIFICATIONS_MANAGEMENT.CONDITION_MONITOR.TAB',
      icon: 'fc-capsule-set',
      component: <ConditionMonitorTable />,
    },
    {
      id: 'organizer',
      title: 'NOTIFICATIONS_MANAGEMENT.REPORT.TAB',
      icon: 'fc-report',
      component: <ReportTable />,
    },
  ]);

  useEffect(() => {
    if (_.isEmpty(sqWorkbenchStore.currentUser)) {
      setCurrentUser();
    }
  }, []);

  return (
    <div data-testid="notificationsPage" className="flexFill height-maximum backgroundColorOffWhite overflowYAuto">
      <div className="marginAuto max-width-1300 min-height-maximum p30 backgroundColorWhite">
        <div className="flexColumnContainer flexSpaceBetween mb15">
          <h3>{t('NOTIFICATIONS_MANAGEMENT.TITLE')}</h3>
        </div>

        <Tabs>
          {_.map(notificationTabs, (tab, index) => (
            <Tab
              eventKey={_.toString(index)}
              key={tab.id}
              title={<TabTitle title={tab.title} icon={tab.icon} />}
              id={`${tab.id}Tab`}>
              <div id={`${tab.id}Notification`}>{tab.component}</div>
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};
