import { TableLoadingIndicator } from '@/core/TableLoadingIndicator.molecule';
import React, { useEffect, useRef, useState } from 'react';
import { Table, TableColumn } from '@/core/Table.atom';
import { useTableSelect } from '@/notifications/management/hooks/useTableSelect.hook';
import { Paginator } from '@/core/Paginator.molecule';
import { useTableFilterSort } from '@/notifications/management/hooks/useTableFilterSort.hook';
import { LoadingTableWrapper } from '@/notifications/management/LoadingTableWrapper';
import { AnyProperty, WithProperty } from '@/utilities.types';
import { BulkTableActions, BulkTableActionsConfig } from '@/notifications/management/BulkTableActions';
import { AxiosPromise } from 'axios';
import { TableSort } from '@/notifications/notification.types';
import { errorToast } from '@/utilities/toast.utilities';

export interface FetchItemsParams<T = string> {
  limit: number;
  offset: number;
  sortAsc?: boolean;
  sortProperty?: T;
  searchParams?: Record<string, string>;
}

export interface PaginationTableProps<T> {
  columns: TableColumn<T>[];
  fetchItems: (options: FetchItemsParams) => AxiosPromise<WithProperty<{ totalResults: number }>>;
  itemsAccessor: string;
  reloadTableTrigger?: { reload: true };
  emptyPlaceHolder?: string | React.ReactNode;
  bulkActionsConfig?: BulkTableActionsConfig<T>[];
  defaultFilters?: Record<string, string>;
  defaultSort?: TableSort;
  extraFilters?: AnyProperty;
  testId?: string;
}

export const PaginationTable = <T extends WithProperty<{ id: string }>>({
  columns,
  fetchItems,
  itemsAccessor,
  reloadTableTrigger,
  emptyPlaceHolder,
  bulkActionsConfig,
  defaultFilters,
  defaultSort,
  extraFilters,
  testId = 'paginationTable',
}: PaginationTableProps<T>) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState<T[]>();
  const [isLoading, setLoading] = useState(false);

  const { searchParams, sort, onFilter, onSort, hasSearchParams } = useTableFilterSort({
    defaultFilters,
    defaultSort,
    extraFilters,
  });
  const { allSelected, selectedIds, onRowSelect, onSelectAll, clearSelectedRows } = useTableSelect(items);

  const isSearching = useRef(hasSearchParams());

  const { sortProperty, sortAscending } = sort;

  useEffect(() => {
    setLoading(true);

    fetchItems({ limit, offset: (page - 1) * limit, sortAsc: sortAscending, sortProperty, searchParams })
      .then(({ data }) => {
        const { totalResults = 0 } = data;
        setItems(data[itemsAccessor]);
        setTotalCount(totalResults);
        isSearching.current = hasSearchParams();
      })
      .catch((e) => errorToast({ httpResponseOrError: e, displayForbidden: true }))
      .finally(() => {
        setLoading(false);
        clearSelectedRows();
        setItems((items) => (!items ? [] : items));
      });
  }, [limit, sortAscending, sortProperty, searchParams, page, reloadTableTrigger]);

  if (!items) {
    return <TableLoadingIndicator testId="initialLoading" />;
  }

  if (items.length === 0 && !isSearching.current) {
    return <>{emptyPlaceHolder}</>;
  }

  return (
    <div data-testid={`${testId}Container`} className="flexRowContainer flexFill">
      {bulkActionsConfig && (
        <BulkTableActions
          config={bulkActionsConfig}
          selectedIds={selectedIds}
          clearSelectedRows={clearSelectedRows}
          items={items}
        />
      )}

      <LoadingTableWrapper isLoading={isLoading} items={items}>
        <>
          <Table
            testId={testId}
            tableClass="fixedHeaderTable"
            columns={columns}
            items={items}
            selectAll={allSelected}
            selectedIds={selectedIds}
            selectAllCallback={onSelectAll}
            onRowSelectCallback={({ id }) => onRowSelect(id)}
            sortTableCallback={onSort}
            sortAscending={sortAscending}
            sortProperty={sortProperty}
            filterTableCallback={({ value }, field) => {
              isSearching.current = true;
              onFilter(field, value);
            }}
            searchParams={searchParams}
          />

          <Paginator
            pageSize={limit}
            setPageSize={(limit) => {
              setPage(1);
              setLimit(limit);
            }}
            gotoPage={setPage}
            pageNumber={page}
            pageCount={totalCount / limit}
            total={totalCount}
          />
        </>
      </LoadingTableWrapper>
    </div>
  );
};
