import { TextButton } from '@/core/TextButton.atom';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

export interface BulkTableActionOptions<T> {
  selectedIds: string[];
  clearSelectedRows: () => void;
  items: T[];
}

export interface BulkTableActionsConfig<T> {
  id: string;
  onClick: (options: BulkTableActionOptions<T>) => void;
  label: string;
}

export interface BulkTableActionsProps<T> extends BulkTableActionOptions<T> {
  config: BulkTableActionsConfig<T>[];
}

export const BulkTableActions = <T,>({ config, ...options }: BulkTableActionsProps<T>) => {
  const isNothingSelected = _.isEmpty(options.selectedIds);

  return (
    <div data-testid="bulkActions" className="flexColumnContainer mt5">
      {_.map(config, ({ id, onClick, label }, index) => (
        <TextButton
          key={label}
          id={id}
          testId={id}
          onClick={() => onClick(options)}
          label={label}
          extraClassNames={classNames({ mr5: index < config.length - 1 })}
          disabled={isNothingSelected}
        />
      ))}
    </div>
  );
};
