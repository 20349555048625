import _ from 'lodash';
import React from 'react';
import { DEFAULT_NOTIFICATION_CONFIGURATION } from '@/notifications/notifications.constants';
import { NotificationConfigurationOutputV1 } from '@/sdk';
import { readOnlyRecipientsList } from '@/notifications/notifications.utilities';

interface EmailRecipientsReadOnlyProps {
  notificationConfiguration: NotificationConfigurationOutputV1;
}

export const EmailRecipientsReadOnly: React.FunctionComponent<EmailRecipientsReadOnlyProps> = ({
  notificationConfiguration = DEFAULT_NOTIFICATION_CONFIGURATION,
}) => {
  return (
    <ul className="unstyled-list mb0">
      {_.map(
        readOnlyRecipientsList([
          ...notificationConfiguration.toEmailRecipients,
          ...notificationConfiguration.ccEmailRecipients,
          ...notificationConfiguration.bccEmailRecipients,
        ]),
        (recipient) => (
          <li key={`readonly-${recipient}`}>{recipient}</li>
        ),
      )}
    </ul>
  );
};
