import { Icon } from '@/core/Icon.atom';
import { TableLoadingIndicator } from '@/core/TableLoadingIndicator.molecule';
import React from 'react';

interface LoadingTableWrapperProps<T> {
  isLoading: boolean;
  items: T[] | undefined;
  children: React.ReactNode;
}

export const LoadingTableWrapper = <T,>({ isLoading, items, children }: LoadingTableWrapperProps<T>) => {
  if (!items) {
    return <TableLoadingIndicator testId="initialLoading" />;
  }

  return (
    <div className="flexFill" data-testid="content">
      {children}

      {isLoading && <Icon testId="loading" icon="fa-spinner fa-pulse fa-spin mt10" large={true} />}
    </div>
  );
};
