import { ConfirmDeleteModal } from '@/core/ConfirmDeleteModal.molecule';
import _ from 'lodash';
import React from 'react';

interface ConfirmDeleteModalProps {
  onClose: () => void;
  onDelete: () => Promise<any>;
  itemCount: number;
}

export const ConfirmDeleteNotificationModal: React.FunctionComponent<ConfirmDeleteModalProps> = ({
  onClose,
  onDelete,
  itemCount,
}) => {
  return (
    <ConfirmDeleteModal
      onClose={onClose}
      action={onDelete}
      headerTranslationKey="NOTIFICATIONS_MANAGEMENT.CONFIRM_DELETE.TITLE"
      headerTranslationParams={{ itemCount }}
      bodyTranslationKey="NOTIFICATIONS_MANAGEMENT.CONFIRM_DELETE.BODY"
      bodyTranslationParams={{ itemCount }}
    />
  );
};
