import { TableSort } from './notification.types';

export const DEFAULT_FILTERS = {
  name: '',
};

export const DEFAULT_SORT: TableSort = {
  sortProperty: 'name',
  sortAscending: true,
};

export const CONDITION_MONITORS_TABLE_CANCELLATION_GROUP = 'conditionMonitorsTable';
export const REPORT_TABLE_CANCELLATION_GROUP = 'reportTable';
